import React, { useState, useEffect } from 'react';
import './App.css';



import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import { builder, BuilderComponent } from '@builder.io/react';

import Iframe from 'react-iframe'


builder.init("cacbfa1405dc440aada6b2743be1e3d4");

export const CatchAllPage = () => {
  const [pageJson, setPage] = useState()

  useEffect(() => {
    builder.get('page', { url: '/home' })
       // The value will be `null` if no page was found
      .promise().then(setPage);}
  , []);

  return pageJson === undefined
    ? <div>Loading</div>
    : pageJson
    ? <BuilderComponent model="page" content={pageJson} />
    : <div>Not Found</div>
}


export const IframeVectorChainWebGl = () => {


  return <Iframe url="https://unitywebglapps.s3-eu-west-1.amazonaws.com/VectorChainWebGL6/index.html" 
  allowFullScreen
  width="100%"
  height="800px"
  id="myId"
  className="myClassname"
  display="block"
  position="relative"/>
}


function App() {
  return (
   <Router>
         <Switch>
     <Route path="/exhibit">
       <IframeVectorChainWebGl />
       </Route>
     <Route component={CatchAllPage} />
     </Switch>
  </Router>
  );
}

export default App;
